import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import * as css from "./imageAndTitle.module.css"

function ImageAndTitle({ longVersion }) {
  return (
    <Link to="/" className={css.a}>
      <div className={css.container}>
        <div className={css.image}>
          <StaticImage
            width={55}
            height={89}
            src="../../images/kopernikNidek_prawo.png"
            alt="Kopernik"
          />
        </div>
        <div className={css.text}>
          {longVersion ? (
            <h1>Zespół Szkolno - Przedszkolny</h1>
          ) : (
            <h1>ZSP im. Mikołaja Kopernika</h1>
          )}
          <p>w Nidku</p>
        </div>
      </div>
    </Link>
  )
}

ImageAndTitle.defaultProps = {
  longVersion: false,
}

export default ImageAndTitle
